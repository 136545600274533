import styled from '@emotion/styled';
import { css } from '@emotion/react';
import CaretIcon from '@zola/zola-ui/src/components/SvgIcons/CaretV2';
import type {
  PartialComponentFontView,
  MediaQueryType,
} from 'components/publicWebsiteV2/util/mappers/types';
import {
  Z_INDEX_MAP,
  Z_INDEX_HERO_IMAGE_KEY,
  Z_INDEX_TEXT_LAYER_KEY,
} from 'components/publicWebsiteV2/util/zIndexConstants';
import { DESKTOP_HEIGHT } from './utils';

export const Title = styled.h1<{
  fontSizeOverride?: {
    desktop: string;
    tablet: string;
    mobile: string;
    mobileXs: string;
  };
}>`
  z-index: ${Z_INDEX_MAP[Z_INDEX_TEXT_LAYER_KEY]};
  overflow-wrap: break-word;
  width: 100%;
  margin: 0; // Defensive styling for bootstrap styles applied in preview

  ${({ fontSizeOverride }) =>
    fontSizeOverride?.desktop ? `font-size: ${fontSizeOverride?.desktop};` : ''}
    ${({ fontSizeOverride, theme }) =>
      fontSizeOverride?.tablet
        ? `${theme.MEDIA_QUERY.TABLET} { font-size: ${fontSizeOverride?.tablet}; }`
        : ''}
    ${({ fontSizeOverride, theme }) =>
      fontSizeOverride?.mobile
        ? `${theme.MEDIA_QUERY.MOBILE} { font-size: ${fontSizeOverride?.mobile}; }`
        : ''}
            ${({ fontSizeOverride, theme }) =>
              fontSizeOverride?.mobileXs
                ? `${theme.MEDIA_QUERY.MOBILE_XS} { font-size: ${fontSizeOverride?.mobileXs}; }`
                : ''}
`;

export const getHeroTitleOverrideStyles = (
  hasBackgroundImage: boolean
): PartialComponentFontView => {
  if (hasBackgroundImage) {
    return { color: 'FFFFFF' };
  }
  return {};
};

export const TitleContainer = styled.div`
  z-index: ${Z_INDEX_MAP[Z_INDEX_TEXT_LAYER_KEY]};
  padding: ${props => props.theme.SPACING.LG} ${props => props.theme.SPACING.XL};
  width: 100%;

  ${props => props.theme.MEDIA_QUERY.MOBILE} {
    padding: 64px ${props => props.theme.SPACING.MD};
  }
`;

export const HeroImage = styled.img<{
  overrideMobileHeight?: number;
  inPreview?: string;
}>`
  object-fit: cover;
  object-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: ${Z_INDEX_MAP[Z_INDEX_HERO_IMAGE_KEY]};

  ${({ theme }) => theme.MEDIA_QUERY.MOBILE} {
    height: ${({ overrideMobileHeight }) => `${overrideMobileHeight}px` || '100vw'};
  }
  ${({ inPreview, overrideMobileHeight }) =>
    inPreview && inPreview === 'MOBILE' ? `height: ${`${overrideMobileHeight}px` || '100vw'}` : ''}
`;

export const MobileScrollDownCaret = styled(CaretIcon)<{ color?: string }>`
  display: none;
  ${props => props.theme.MEDIA_QUERY.MOBILE} {
    display: block;
  }
  color: #${({ color }) => color};
  z-index: ${Z_INDEX_MAP[Z_INDEX_TEXT_LAYER_KEY]};
  &:hover {
    cursor: pointer;
  }
  position: absolute;
  bottom: 20px;
`;

export const getHeroContainerStyles = ({
  hasImage,
  mediaQuery,
  overrideMobileHeight,
}: {
  hasImage?: boolean;
  mediaQuery: MediaQueryType;
  overrideMobileHeight?: number;
}) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  overflow: hidden;

  ${hasImage &&
  `
      min-height: ${DESKTOP_HEIGHT}px;

      ${mediaQuery.MOBILE} {
        min-height: ${overrideMobileHeight ? `${overrideMobileHeight}px` : '100vw'};
      }

      ${Title} {
        filter: drop-shadow(0px 6px 10px rgba(0,0,0,.2));
      }
    `}
`;
