import { Device } from 'lib/universal/utils/devices';

export const DESKTOP_HEIGHT = 600;

export const getHeroImageSizeParams = (
  containerDimensions: { width: number; height: number },
  imageDimensions: { width?: number; height?: number },
  device?: Device
): string => {
  const { height: containerHeight, width: containerWidth } = containerDimensions;
  const { height: imageHeight = 0, width: imageWidth = 0 } = imageDimensions;
  const imageAspectRatio = imageWidth / imageHeight;
  const heroSpaceAspectRatio = containerWidth / containerHeight;
  const requestWidth = heroSpaceAspectRatio > imageAspectRatio;
  return `?${
    requestWidth
      ? `w=${device?.isHighPixelDensity() ? containerWidth * 2 : containerWidth}`
      : `h=${device?.isHighPixelDensity() ? containerHeight * 2 : containerHeight}`
  }`;
};
