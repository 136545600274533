import { useEffect, useState } from 'react';

export type ImageDimensions = {
  width?: number;
  height?: number;
};

export default function useImageDimensions(url?: string): ImageDimensions {
  const [dimensions, setDimensions] = useState<ImageDimensions>({});

  useEffect(() => {
    if (url && document) {
      const img = document.createElement('img');
      img.src = url;

      img.onload = (): void => {
        const { width, height } = img;
        setDimensions({ width, height });
      };
    }
  }, [url]);

  return dimensions;
}
